.admin-settings-nav {
    overflow-x: auto;
    overflow-y: hidden;
    height: 57px;
    flex: 0 0 auto;
    max-height: 57px;
    flex-wrap: nowrap;
}

.admin-settings-nav .nav-item, .admin-settings-nav .nav-item button {
    width: max-content;
}